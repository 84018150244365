import clsx from 'clsx';
import { useEffect, useState } from 'react';
import FlexView from 'react-flexview/lib';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import EmptyPage from '../components/EmptyPage';
import Footer from '../components/Footer';
import Header from '../components/Header';
import PageLayout from '../components/PageLayout';
import Button from '../components/UI/Button';
import Chip from '../components/UI/Chip';
import IconButton from '../components/UI/IconButton';
import Modal from '../components/UI/Modal';
import Table from '../components/UI/PaginatedTable/Table';
import TableBulkActions from '../components/UI/PaginatedTable/TableBulkActions';
import Snackbar from '../components/UI/Snackbar';
import { useExtendedState } from '../hooks/useExtendedState';
import failureIcon from '../images/important_note.svg';
import localStorageService from '../Shared/LocalStorageService';
import Spinner from '../Shared/Spinner';
import ConfigDisplay from './ConfigDisplay';
import styles from './CreatedInvitations.module.css';
import CreatedInvitationsPresenter from './CreatedInvitationsPresenter';
import { STATUS } from './Status';

const CreatedInvitations = () => {
  const { t } = useTranslation();
  const invitationsContext = useSelector((state) => state.invitations.context);
  const [toBeCopiedIDs, setToBeCopiedIDs] = useState([]);
  const [createdInvitations, setCreatedInvitations, getLatestValueOfInvitations] = useExtendedState(
    [],
  );
  const [modalInfo, setModalInfo] = useState({
    show: false,
  });
  const [pageSize, setPageSize] = useState(10); //No.of rows per page
  const [dataLength, setDataLength] = useState(null);
  const [showInvitationsWithStatus, setShowInvitationsWithStatus] = useState(
    JSON.parse(localStorageService.getLocalStorageValue('filter-invitation-status')) || {
      [STATUS.CREATED.key]: true,
      [STATUS.COPIED.key]: true,
      [STATUS.REGISTERED.key]: true,
      [STATUS.FINISHED.key]: true,
      [STATUS.COMPLETED.key]: true,
      [STATUS.EXPIRED.key]: true,
    },
  );
  const [tempShowInvitationsWithStatus, setTempShowInvitationsWithStatus] =
    useState(showInvitationsWithStatus);
  const [showStatusFilter, setShowFilterStatus] = useState(false);
  const [showConfig, setShowConfig] = useState(false);
  const [configToShow, setConfigToShow] = useState({});
  const [snackBar, setSnackBar] = useState({
    showSnackbar: false,
    message: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const headerActionButtonConfig = () => {
    let config = {
      elemType: 'button',
      metadata: {
        text: t('user.dashboard.create-invitations'),
        click: () => goToCreateInvitation(),
        style: styles.custom_header_button_style,
      },
    };
    return config;
  };
  const customHeaderStyles = {
    header_container: styles.additional_style_header_container,
  };
  const createdInvitationsPresenter = new CreatedInvitationsPresenter();

  useEffect(() => {
    if (!showStatusFilter) {
      submitOrCancelFilters(tempShowInvitationsWithStatus);
    }
  }, [showStatusFilter]);

  const loadCreatedInvitations = async (pageNumber, productId, shouldResetData, newFilters) => {
    setIsLoading(true);
    const createdInvitationsVm = await createdInvitationsPresenter.getInvitations(
      pageSize,
      pageNumber,
      productId,
      updateField,
      setMultipleCopyIDs,
      newFilters || showInvitationsWithStatus,
    );
    setIsLoading(false);
    if (createdInvitationsVm.success) {
      const dataLen = createdInvitationsPresenter.getDataLength();
      setDataLength(dataLen);
      let createdInvitationsCopy = [...createdInvitations];
      if (createdInvitationsCopy.length === 0 || shouldResetData) {
        createdInvitationsCopy = new Array(dataLen);
        createdInvitationsCopy.fill({}, 0);
      }
      const startIndex = (pageNumber - 1) * pageSize;
      createdInvitationsCopy.splice(
        startIndex,
        createdInvitationsVm.result.length,
        ...createdInvitationsVm.result,
      );
      setCreatedInvitations(createdInvitationsCopy);
    } else {
      let errorMessage = [];
      createdInvitationsVm.error.forEach((error) => {
        errorMessage.push(t(error.key, error.parameters));
      });
      showDialog(errorMessage.join(' '));
    }
  };

  const setPage = async (pageNumber) => {
    if (!invitationsContext) {
      return;
    }
    const startIndexOfCurrentPage = (pageNumber - 1) * pageSize;
    if (Object.keys(createdInvitations[startIndexOfCurrentPage]).length === 0) {
      loadCreatedInvitations(pageNumber, invitationsContext.productId);
    }
    setToBeCopiedIDs([]);
  };

  const resetItemsPerPage = (newPageSize) => {
    setPageSize(newPageSize);
  };

  const getItemsPerPageData = () => {
    const callback = resetItemsPerPage;
    return { callback };
  };

  const toggleShowFilterDropDown = () => {
    setShowFilterStatus((showFilterStatus) => !showFilterStatus);
  };

  const modalClose = () => {
    setModalInfo({
      show: false,
      text: null,
      formActions: {
        rightButton: {
          text: null,
          action: null,
        },
      },
      icon: null,
    });
  };

  const showDialog = (errorMessage) => {
    setModalInfo({
      show: true,
      text:
        errorMessage ||
        t('error.message.something-went-wrong') + ' ' + t('error.message.please-try-later'),
      formActions: {
        rightButton: {
          text: 'Close',
          action: modalClose,
        },
      },
      icon: failureIcon,
    });
  };

  const getFilterButtonMetadata = () => {
    let filterLength = Object.values(showInvitationsWithStatus).filter((v) => v === false).length;
    let filteredKeys = [];
    let additionalText = '';
    if (filterLength > 0) {
      filteredKeys = Object.keys(showInvitationsWithStatus).filter(
        (key) => showInvitationsWithStatus[key] === false,
      );
      if (filterLength > 2)
        additionalText =
          ' (' + t(STATUS[filteredKeys[0]].name) + ', +' + `${filterLength - 1}` + ')';
      else {
        additionalText = [];
        filteredKeys.forEach((key) => additionalText.push(t(STATUS[key].name)));
        additionalText = ' ' + additionalText.join(', ');
      }
    }
    return {
      text: (
        <span>
          {t('general.hidden')}
          <span className={styles.external_color}>{additionalText || ' ' + t('general.none')}</span>
        </span>
      ),
      rightIcon: 'arrow_down.svg',
      click: () => toggleShowFilterDropDown(),
      style: styles.filter_button_style,
    };
  };

  const toggleTempFilters = (status) => {
    setTempShowInvitationsWithStatus((tempShowInvitationsWithStatus) =>
      Object.assign({}, tempShowInvitationsWithStatus, {
        [status]: !tempShowInvitationsWithStatus[status],
      }),
    );
  };

  const getChipMetadata = (status) => {
    return {
      text: t(STATUS[status].name),
      style: Object.assign({}, STATUS[status].style),
    };
  };

  const submitOrCancelFilters = (newFilters) => {
    if (newFilters) {
      setShowInvitationsWithStatus(newFilters);
      localStorageService.setLocalStorageValue(
        'filter-invitation-status',
        JSON.stringify(newFilters),
      );
      if (showInvitationsWithStatus !== newFilters)
        loadCreatedInvitations(1, invitationsContext.productId, true, newFilters);
    }
  };

  //Change when multiple IDs are checked or unchecked for copying.
  const setMultipleCopyIDs = (id, value) => {
    if (value) {
      //Add ID on check
      setToBeCopiedIDs((toBeCopiedIDs) => {
        if (!toBeCopiedIDs.includes(id)) return [...toBeCopiedIDs, id];
        else return toBeCopiedIDs;
      });
    } else {
      //Delete ID on uncheck
      setToBeCopiedIDs((toBeCopiedIDs) => {
        if (toBeCopiedIDs.includes(id))
          return toBeCopiedIDs.filter((toBeCopiedID) => toBeCopiedID !== id);
        else return toBeCopiedIDs;
      });
    }
    setCreatedInvitations((prevCreatedInvitations) => {
      return prevCreatedInvitations.map((createdInvitation) => {
        if (createdInvitation.id === id) {
          createdInvitation.multiplecopy.props.checked = value;
          return createdInvitation;
        }
        // Keep the other items unchanged
        return createdInvitation;
      });
    });
  };

  //Update any column field of a particular invitation.
  const updateField = (id, field, fieldValue, message, success, errorObj) => {
    let createdInvitationsCopy = [];
    if (id) {
      getLatestValueOfInvitations().then((latestCreatedInvitations) => {
        createdInvitationsCopy = [...latestCreatedInvitations];
        let indexToUpdate = createdInvitationsCopy.findIndex((invitation) => invitation.id === id);
        if (indexToUpdate > -1) {
          if (field === 'status') {
            let currentStatus =
              createdInvitationsCopy[indexToUpdate][field].props.metadata.currentStatus;
            let statusToUpdate = fieldValue.props.metadata.currentStatus;
            if (STATUS[currentStatus].value > STATUS[statusToUpdate].value) {
              //Do not update the status if already copied.: i.e., DO NOTHING in this case.
            } else {
              createdInvitationsCopy[indexToUpdate][field] = fieldValue;
              setCreatedInvitations(createdInvitationsCopy);
            }
          } else {
            createdInvitationsCopy[indexToUpdate][field] = fieldValue;
            setCreatedInvitations(createdInvitationsCopy);
          }
        }
      });
    }

    let displayMessage = [];
    if (message) displayMessage.push(t(message));
    if (!success && errorObj) {
      errorObj.forEach((error) => {
        displayMessage.push(t(error.key, error.parameters));
      });
    }
    if (displayMessage.length > 0)
      setSnackBar({
        showSnackbar: true,
        message: displayMessage.join(' '),
      });
  };

  const showConfigModal = (value, config) => {
    setShowConfig(value);
    setConfigToShow(config);
  };

  const getColumns = () => {
    const columns = {
      multiplecopy: {
        title: '',
        customElement: true,
        renderCheckbox: true,
      }, // New checkbox column
      email: {
        title: t('product.details.creator'),
      },
      created: {
        title: t('product.details.creation-date'),
      },
      expired: {
        title: t('product.details.expiration-date'),
      },
      token: {
        title: t('product.details.token'),
      },
      comment: {
        title: t('product.details.comment'),
        customElement: true, //indicates whether we want to render a custom element
      },
      status: {
        title: t('general.status'),
        customElement: true,
      },
      actions: {
        title: '',
      },
    };
    return columns;
  };

  const copyMultipleUrisToClipBoard = async () => {
    let linksToCopy = [];
    let tempToBeCopiedIDs = [...toBeCopiedIDs];

    createdInvitations.map((createdInvitation) => {
      if (tempToBeCopiedIDs.includes(createdInvitation.id)) {
        linksToCopy.push(createdInvitation.uri);
      }
      return true;
    });
    let copiedLinks = linksToCopy.join('\n');
    await navigator.clipboard.writeText(copiedLinks);
    const response = await createdInvitationsPresenter.updateMultipleStatuses(STATUS.COPIED.key, [
      ...toBeCopiedIDs,
    ]);
    let message;
    if (response) {
      let statusElement = createdInvitationsPresenter.getStatusChip(STATUS.COPIED.key);
      toBeCopiedIDs.forEach((copiedID) => {
        updateField(copiedID, 'status', statusElement);
      });
      message = t('product-details-links-copied-to-clipboard', { num: toBeCopiedIDs.length });
    } else {
      message = t('product-details-error-copy-link-s') + ' ' + t('error.message.please-try-later');
    }
    setSnackBar({ showSnackbar: true, message });
    toBeCopiedIDs.forEach((copiedID) => {
      setMultipleCopyIDs(copiedID, false);
    });
  };

  const getTableBulkActions = () => {
    let actions = [
      {
        text: t('general.copy'),
        // text: 'Copy',
        style: styles.copy_button_style,
        click: copyMultipleUrisToClipBoard,
      },
    ];
    return actions;
  };

  const getItemsSelectedMessage = () => {
    return toBeCopiedIDs.length > 0
      ? t('product-details-x-invitations-selected', { num: toBeCopiedIDs.length })
      : '';
  };

  const toggleBulkActionSelection = (currentPage, valueToSet, isClicked) => {
    /*If the checkbox has been actually clicked, the value of "clicked" will be "true".
        then we perform the toggle behaviour (i.e., "select all" or "de-select all")
        otherwise we do nothing.*/
    if (isClicked) {
      //valueToSet indicates checked or unchecked. if true, select all.
      setCreatedInvitations((prevCreatedInvitations) => {
        return prevCreatedInvitations.map((createdInvitation, index) => {
          if (
            index >= (currentPage - 1) * pageSize &&
            index < (currentPage - 1) * pageSize + pageSize
          ) {
            createdInvitation.multiplecopy.props.checked = valueToSet;
            return createdInvitation;
          }
          // Keep the other items unchanged
          return createdInvitation;
        });
      });
    }
  };

  const handleSnackbarClose = () => {
    setSnackBar({
      showSnackbar: false,
      message: '',
    });
  };

  const goToCreateInvitation = () => {
    navigate(`/createinvitations`);
  };

  useEffect(() => {
    if (!invitationsContext) {
      navigate('/dashboard');
    } else {
      if (
        invitationsContext.productId &&
        localStorageService.getLocalStorageValue(
          'filter-invitation-status-' + invitationsContext.productId,
        )
      ) {
        //TODO: Remove stale key deletion in next release
        localStorageService.deleteLocalStorageValue(
          'filter-invitation-status-' + invitationsContext.productId,
        );
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (invitationsContext) {
      loadCreatedInvitations(1, invitationsContext.productId, true);
    }
  }, [pageSize, invitationsContext]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!invitationsContext) {
    return null;
  }

  return (
    <PageLayout>
      <FlexView column className={styles.page_container}>
        <Header
          title={invitationsContext.productName}
          customElement={headerActionButtonConfig()}
          customStyles={customHeaderStyles}
          moreDetails={{ [t('user.dashboard.available-units')]: invitationsContext.availableUnits }}
        />
        <FlexView className={styles.table_container} column shrink={0}>
          <Snackbar
            open={snackBar.showSnackbar}
            onClose={handleSnackbarClose}
            message={snackBar.message}
          />
          <FlexView className={styles.action_menu_container}>
            <FlexView className={styles.filter_contents_container} column>
              <span className={styles.filter_title}>{t('product.details.filter-status')}</span>
              <Button metadata={getFilterButtonMetadata()} />
              {showStatusFilter && (
                <>
                  <div
                    id="overlay"
                    className={styles.overlay}
                    onClick={() => toggleShowFilterDropDown()}
                  ></div>
                  <FlexView column className={styles.filter_dropdown_container}>
                    <FlexView column className={styles.menu_items_container}>
                      {Object.keys(STATUS).map((eachStatus, index) => {
                        return (
                          <MenuItem
                            key={index}
                            chipMetadata={getChipMetadata(STATUS[eachStatus].key)}
                            showInvitationsWithStatus={
                              tempShowInvitationsWithStatus[STATUS[eachStatus].key]
                            }
                            click={() => toggleTempFilters(STATUS[eachStatus].key)}
                          />
                        );
                      })}
                    </FlexView>
                  </FlexView>
                </>
              )}
            </FlexView>
            <TableBulkActions
              itemsSelectedMessage={getItemsSelectedMessage()}
              numberOfitemsSelectedForBulkActions={toBeCopiedIDs.length}
              actions={getTableBulkActions()}
            />
          </FlexView>
          {dataLength > 0 && (
            <FlexView className={styles.table_container_2} column>
              <Table
                data={createdInvitations}
                dataLength={dataLength}
                callback={setPage}
                columns={getColumns()}
                itemsPerPageData={getItemsPerPageData()}
                rowsPerPage={pageSize}
                alwaysPaginated={true}
                numberOfitemsSelectedForBulkActions={toBeCopiedIDs.length}
                toggleBulkActionSelection={toggleBulkActionSelection}
                detailedViewExists={true}
              />
            </FlexView>
          )}
          {showConfig && (
            <ConfigDisplay config={configToShow} onClose={() => showConfigModal(false)} />
          )}
          {isLoading && <Spinner />}
          {!isLoading && dataLength <= 0 && <EmptyPage message={t('error.no-invitation-links')} />}
        </FlexView>
        {modalInfo.show && (
          <Modal onClose={modalInfo.close} formActions={modalInfo.formActions}>
            <FlexView column className={styles.modal_content_container}>
              <img src={modalInfo.icon} className={styles.modal_content_icon} alt="modal_icon" />
              <span className={styles.modal_content_text}>{modalInfo.text}</span>
            </FlexView>
          </Modal>
        )}
        <Footer />
      </FlexView>
    </PageLayout>
  );
};

const MenuItem = ({ click, showInvitationsWithStatus, chipMetadata }) => {
  const { t } = useTranslation();
  return (
    <FlexView className={clsx(styles.individual_menu_container)}>
      <Chip metadata={chipMetadata} />
      {!showInvitationsWithStatus && (
        <IconButton
          metadata={{
            src: 'eye-slash.svg',
            tooltipText: 'product.details.show-status',
            tooltipPlacement: 'right',
            click: click,
          }}
        />
      )}
      {showInvitationsWithStatus && (
        <IconButton
          metadata={{
            src: 'eye.svg',
            tooltipText: 'product.details.hide-status',
            tooltipPlacement: 'right',
            click: click,
          }}
        />
      )}
    </FlexView>
  );
};

export default CreatedInvitations;
