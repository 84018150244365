import { UserService } from '@zortify/react-keycloak';
import axios from 'axios';

import { UNDEFINED } from '../consts/CommonConstants';
import { apiService } from '../services/ApiService';
import { authService } from '../services/AuthService';
import userProfileService from '../services/UserProfileService';

class HttpGateway {
  axiosObj = axios.create({});
  /**
   * Sets the jwt token as part of Authorization header which
   * will be passed to all the API calls to the backend.
   */
  setToken = () => {
    let token = authService.getJWTToken();
    let zCompanyId = userProfileService.getZCompanyId();
    let userPreferredLocale = userProfileService.getUserPreferredLocale();
    let config = {};
    if (token) {
      config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Z-Company-Id': zCompanyId,
          'Z-User-Locale': userPreferredLocale || window.navigator.language,
        },
        withCredentials: true,
      };
    }
    this.axiosObj = axios.create(config);
  };

  tokenRefreshCallback = () => {
    this.setToken();
  };

  tokenRefreshErrorCallback = () => {
    authService.doLogout();
  };

  get = async (api, params, pathVar, placeholderValues) => {
    let url = apiService.get(api, placeholderValues);
    await UserService.updateToken(this.tokenRefreshCallback, this.tokenRefreshErrorCallback);
    if (pathVar) {
      url += '/' + pathVar;
    }
    let response = await this.axiosObj
      .get(url, {
        params: params,
      })
      .catch((err) => {
        console.log(err);
        return UNDEFINED;
      });
    return response;
  };

  post = async (api, params, headers) => {
    let url = apiService.get(api);
    await UserService.updateToken(this.tokenRefreshCallback, this.tokenRefreshErrorCallback);
    let response = await this.axiosObj.post(url, params || {}, headers);
    return response;
  };

  put = async (api, data, pathVar, placeholderValues) => {
    let url = apiService.get(api, placeholderValues);
    if (pathVar) {
      url += '/' + pathVar;
    }
    await UserService.updateToken(this.tokenRefreshCallback, this.tokenRefreshErrorCallback);
    let response = await this.axiosObj.put(url, data).catch((err) => {
      console.log(err);
      return UNDEFINED;
    });
    return response;
  };

  patch = async (api, data, pathVar, placeholderValues) => {
    let url = apiService.get(api, placeholderValues);
    if (pathVar) {
      url += '/' + pathVar;
    }
    await UserService.updateToken(this.tokenRefreshCallback, this.tokenRefreshErrorCallback);
    let response = await this.axiosObj.patch(url, data).catch((err) => {
      console.log(err);
      return UNDEFINED;
    });
    return response;
  };
}

const httpGateway = new HttpGateway();
export default httpGateway;
