import { HTTP_RESPONSE_OK, HTTP_STATUS_OK } from '../consts/HTTPResponseCodes';
import i18n from '../i18n/config';
import httpGateway from '../Shared/HttpGateway';

class HttpMessenger {
  reduceParameters = (parameters) => {
    return parameters.reduce((acc, obj) => {
      acc[obj.key] = i18n.t(obj.value);
      return acc;
    }, {});
  };

  apiCall = async (method, api, params, data, pathVar, placeholderValues) => {
    let response = null;
    const unknownErrorResponse = {
      success: false,
      error: [
        {
          key: 'error.message.something-went-wrong',
          parameters: {},
        },
        {
          key: 'error.message.please-try-later',
          parameters: {},
        },
      ],
    };

    switch (method) {
      case 'GET':
        response = await httpGateway.get(api, params, pathVar, placeholderValues).catch((err) => {
          return unknownErrorResponse;
        });
        break;
      case 'POST':
        response = await httpGateway.post(api, params).catch((err) => {
          return unknownErrorResponse;
        });
        break;
      case 'PATCH':
        response = await httpGateway.patch(api, data, pathVar, placeholderValues);
        break;
      case 'PUT':
        response = await httpGateway.put(api, data, pathVar, placeholderValues);
        break;
    }
    if (response && response.status === HTTP_RESPONSE_OK) {
      if (response.data && response.data && response.data.status === HTTP_STATUS_OK) {
        return {
          success: true,
          result: response.data.result,
        };
      } else {
        return {
          success: false,
          error: [
            {
              ...response.data.error,
              parameters: this.reduceParameters(response.data.error.parameters || []),
            },
          ],
        };
      }
    } else {
      return unknownErrorResponse;
    }
  };
}

const httpMessenger = new HttpMessenger();
export default httpMessenger;
